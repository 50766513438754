// Imports
// ------
import styled from 'styled-components';
import { breakup, Section, H1 } from '@tackl';

// Exports
// ------
export const Jacket = styled(Section)((props => `
    position: relative;
    background: ${props.theme.bc2};
`));

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const Title = styled(H1)((props => `
    color: ${props.theme.white};
    font-weight: 600;
    text-align: center;

    margin-bottom: 2.4rem;

    ${breakup.large && ` margin-bottom: 3.6rem; `}
`));