// Imports
// ------
import styled from 'styled-components';
import { breakup, Section, H5, P } from '@tackl';

// Exports
// ------
export const Jacket = styled(Section)((props => `
    position: relative;
    z-index: 1;

    transition: all 1s ease;
    position: relative;
    display: block;
    width: 100%;
    white-space: nowrap;
    overflow-y: visible;

    span { margin: 0 !important; }
`));

export const Content = styled.div`
    display: inline-flex;
    align-items: flex-start;

    .gatsby-image-wrapper {
        display: block;
        margin: 0 1.8rem;
        width: calc(8.333vw * 3.5 - 1.2rem);

        &:nth-child(even) {
            margin-top: 12rem !important;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: contain !important;
        }
    }
`;

export const Blade = styled.div`
    position: absolute;
    z-index: -1;
    width: 100%;
    bottom: 12rem;

    img { width: 100%; }
`;