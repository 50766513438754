// Imports
// ------
import React from 'react';
import Button from '@parts/Button';
import { Row, Column } from '@waffl';

// Styles
// ------
import { Jacket, Content, Title } from './styles';

// Component
// ------
const CallToAction = () => {
    return (
        <Jacket className="cursor-intro" pad>
            <Row isExpanded isCenter>
                <Column small={12} medium={10} pushMedium={1}>
                    <Content>
                        <Title>Ready to get rid of those tired carpets?</Title>
                        <Button alt label="Book Sam &amp; Sharnie Today" action="tel:07824057430" />
                    </Content>
                </Column>
            </Row>
        </Jacket>
    )
}

export default CallToAction;