// Imports
// ------
import React from 'react';
// import Cursor from '@parts/Cursor';
import Hero from '@parts/Home/Hero';
import Intro from '@parts/Home/Intro';
import Gallery from '@parts/Home/Gallery';
import Services from '@parts/Home/Services';
import CTA from '@parts/Home/CTA';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

const IndexPage = ({ location }) => {
	// NOTE • Breakpoints
	const bp = useBreakpoint();

	return (
		<>
			{/* {bp.large && <Cursor location={location} />} */}
			<Hero />
			<Intro />
			<Gallery />
			<Services />
			<CTA />
		</>
	);
}

export default IndexPage;