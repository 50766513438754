// Imports
// ------
import styled from 'styled-components';

// Exports
// ------
export const Jacket = styled.div`
    position: relative;
    z-index: 6;

    img { width: 100%; }
`;