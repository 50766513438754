// Imports
// ------
import React from 'react';
import Logo from '@parts/Logo';
import Who from './Who';
import TitleFrame from '@images/hero-title.svg';
import Spinny from '@images/spinny.svg';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Row, Column } from '@waffl';
import { useDato } from './data';

// Styles
// ------
import {
    Jacket,
    Image,
    Content,
    Label,
    Title,
    Spinner,
    Frame,
    Headline
} from './styles';

// Component
// ------
const Hero = () => {
    // NOTE • Data
    const { image } = useDato();

    return (
        <Jacket>
            <Row isExpanded>
                <Column small={5} medium={3} large={2} mpad>
                    <Logo />
                </Column>
                <Column small={7} medium={5} pushMedium={4} large={4} pushLarge={6} mpad>
                    <Who />
                </Column>
            </Row>

            <Content>
                <Row isExpanded>
                    <Column small={6} medium={4} pushMedium={7} mpad>
                        <Label>Sheffield,<br />United Kingdom</Label>
                    </Column>
                </Row>
                <Row isExpanded>
                    <Column small={6} pushSmall={5} medium={3} pushMedium={9} mpad>
                        <Label ar>Call for<br />bookings</Label>
                    </Column>
                </Row>
            </Content>

            <Title>
                <Spinner>
                    <img src={Spinny} alt="" />
                </Spinner>
                <Frame>
                    <img src={TitleFrame} alt="" />
                </Frame>
                <Headline>
                    Yorkshire's<br />
                    most reliable<br />
                    carpet fitters
                </Headline>
            </Title>

            <Image>
                <GatsbyImage image={image.data} alt="" />
            </Image>
        </Jacket>
    );
}

export default Hero;