// Imports
// ------
import React from 'react';
import Icon from '@icons';

// Styles
// ------
import { Jacket } from './styles';

// Component
// ------
const Button = ({ alt, label, action }) => (
    <Jacket className="cursor-hover" alt={alt} href={action}>{label} <Icon type="chev-right" /></Jacket>
);

export default Button;