// Imports
// ------
import React from 'react';
import Button from '@parts/Button';
import { Row, Column } from '@waffl';

// Styles
// ------
import {
    Jacket,
    Line,
    Title,
    Para
} from './styles';

// Component
// ------
const Intro = () => {
    return (
        <Jacket className="cursor-intro" pad>
            <Row isExpanded>
                <Column small={12} medium={5} pushMedium={2} mpad>
                    <Line />
                    <Title>Join the thousands of happy customers. With over 14 years experience of providing Yorkshire with carpet, lino and carpet tile flooring, you can have the home you dreamed of.</Title>
                </Column>
            </Row>
            <Row isExpanded>
                <Column small={12} medium={5} pushMedium={6} mpad>
                    <Para>Gone are the days of trolling the internet for your next carpet fitter. You've found us. We work in the partnership with TwelveTree Furnishings Ltd in ensuring you have the best service possible.</Para>
                    <Button label="Book Sam &amp; Sharnie Today" action="tel:000" />
                </Column>
            </Row>
        </Jacket>
    )
}

export default Intro;