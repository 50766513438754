// Imports
// ---------
import styled from 'styled-components';
import { semantics } from './semantics';
import {
	h1Styles,
	h2Styles,
	h3Styles,
	h4Styles,
	h5Styles,
	h6Styles,
	pStyles,
	spanStyles,
	emStyles,
} from './type';
import { breakpointUp, breakpointDown, breakpointOnly } from './breakpoints';

// --------------
// 1. Semantics
// --------------
export const Section = styled.section`
	${semantics}
`;
export const Div = styled.div`
	${semantics}
`;
export const Main = styled.main`
	${semantics}
`;
export const Nav = styled.nav`
	${semantics}
`;
export const Article = styled.article`
	${semantics}
`;
export const Header = styled.header`
	${semantics}
`;
export const Footer = styled.footer`
	${semantics}
`;

// --------------
// 3. Typography
// --------------
export const H1 = styled.h1`
	${h1Styles}
`;
export const H2 = styled.h2`
	${h2Styles}
`;
export const H3 = styled.h3`
	${h3Styles}
`;
export const H4 = styled.h4`
	${h4Styles}
`;
export const H5 = styled.h5`
	${h5Styles}
`;
export const H6 = styled.h6`
	${h6Styles}
`;
export const P = styled.p`
	${pStyles}
`;
export const Em = styled.em`
	${emStyles}
`;
export const Span = styled.span`
	${spanStyles}
`;

// --------------
// 3. Breakpoints
// --------------
export const breakup = breakpointUp;
export const breakdown = breakpointDown;
export const breakonly = breakpointOnly;
