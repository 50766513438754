// Imports
// ------
import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Row, Column } from '@waffl';
import { useDato } from './data';

// Styles
// ------
import {
    Jacket,
    Service,
    Line,
    Title,
    Heading,
    Para,
    Number
} from './styles';

// Component
// ------
const Services = () => {
    // NOTE • Data
    const {
        title,
        serviceList
    } = useDato();

    return (
        <Jacket className="cursor-intro">
            <Row isExpanded>
                <Column small={12} medium={10} pushMedium={1}>
                    <Line />
                    <Title>{title}</Title>
                </Column>
            </Row>
            
            {/* NOTE • SERVICE LIST */}
            <Row isExpanded isCollapsed>
                <Column small={12}>
                {
                    serviceList.map((service, i) =>
                        i % 2 ?
                        (
                            <Service>
                                <Row isExpanded isCollapsed isEqual>
                                    <Column small={12} medium={6} order={2} orderMedium={1}>
                                        <Row isExpanded isCenter>
                                            <Column small={12} medium={10} pushMedium={1} mpad>
                                                <Heading>{service.title}</Heading>
                                                <Para>{service.description}</Para>
                                            </Column>
                                        </Row>
                                    </Column>
                                    <Column small={12} medium={6} order={1} orderMedium={2}>
                                        <Number>0{i+1}</Number>
                                        <GatsbyImage image={service.image.data} alt={service.image.alt} />
                                    </Column>
                                </Row>
                            </Service>
                        ) : (
                            <Service>
                                <Row isExpanded isCollapsed isEqual>
                                    <Column small={12} medium={6}>
                                        <Number>0{i+1}</Number>
                                        <GatsbyImage image={service.image.data} alt={service.image.alt} />
                                    </Column>
                                    <Column small={12} medium={6}>
                                        <Row isExpanded isCenter>
                                            <Column small={12} medium={10} pushMedium={1} mpad>
                                                <Heading>{service.title}</Heading>
                                                <Para>{service.description}</Para>
                                            </Column>
                                        </Row>
                                    </Column>
                                </Row>
                            </Service>
                        )
                    )
                }
                </Column>
            </Row>
        </Jacket>
    )
}

export default Services;