// Imports
// ------
import styled, { css } from 'styled-components';
import { breakup, Section, H3, H5, P, Span } from '@tackl';

// Exports
// ------
export const Jacket = styled(Section)((props => `
    position: relative;
`));

export const Line = styled.div((props => `
    position: relative;
    display: block;
    margin-bottom: 6rem;

    width: 10rem;
    height: 1px;
    background: ${props.theme.bc2};
`));

export const Title = styled(H3)((props => `
    text-transform: initial;
    margin-bottom: 2.4rem;

    ${breakup.xlarge && ` margin-bottom: 3.6rem; `}
`));

export const Service = styled.div((props => `
    position: relative;
`));

export const Heading = styled(H5)((props => css`
    text-transform: initial;
	margin-top: 2.4rem;
    margin-bottom: 1.2rem;

	${breakup.large`
        margin-top: 0;
        margin-bottom: 3.6rem;
    `}
`));

export const Para = styled(P)((props => `
    color: ${props.theme.white};
`));

export const Number = styled(Span)((props => `
    position: absolute;
    z-index: 2;
    top: 0;
    left: 50%;
    transform: translateX(-50%);

    font-family: ${props.theme.heading};
    font-size: 9vw;
    font-weight: 700;
`));