import { useStaticQuery, graphql } from 'gatsby';

export const useDato = () => {
    const data = useStaticQuery(
        graphql`
            query HomeGallery {
                gallery: datoCmsHomepage {
                    images: galleryImages {
                        alt
                        data: gatsbyImageData(
							placeholder: BLURRED
							layout: FULL_WIDTH
						)
                    }
                }
            }
        `
    );

    return data.gallery;
}