// Imports
// ------
import styled, { css } from 'styled-components';
import { P, Span } from '@tackl';

// Exports
// ------
export const Jacket = styled.div`
    position: relative;
    z-index: 6;
`;

const sharedType = css`
    font-size: 1.4rem;
    letter-spacing: 0.02em;
    margin: 0;
`;

export const Label = styled(Span)((props =>`
    ${sharedType}
    color: ${props.theme.bc5};
`));

export const Text = styled(P)((props =>`
    ${sharedType}
    color: ${props.theme.white};
`));