// Imports
// ------
import styled, { css } from 'styled-components';
import { pStyles } from '@tackl-type';

// Exports
// ------
export const Jacket = styled.a((props => css`
    position: relative;
    display: flex;
    align-items: center;
    width: max-content;
    cursor: pointer;

    ${pStyles}
    color: ${props.alt ? props.theme.bc2 : props.theme.white};

    padding: 1.5rem 4.8rem 1.5rem 2.4rem;
    background: ${props.alt ? props.theme.white : props.theme.bc2};
    clip-path: polygon(0 0, 90% 0, 100% 100%, 0% 100%);

    transition: all 0.4s ${props.theme.bezzy};

    svg {
        transition: all 0.4s ${props.theme.bezzy};
        fill: ${props.alt ? props.theme.bc2 : props.theme.white};
    }

    &:hover {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
        padding: 1.5rem 1.6rem 1.5rem 2.4rem;
        background: ${props.theme.bc3};
        color: ${props.alt ? props.theme.white : props.theme.white};

        svg {
            margin-left: 0.6rem;
            fill: ${props.theme.white};
        }
    }
`));