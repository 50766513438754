// Imports
// ------
import React from 'react';
import { Row, Column } from '@waffl';

// Styles
// ------
import {
    Jacket,
    Label,
    Text
} from './styles';

// Component
// ------
const Who = () => (
    <Jacket>
        <Row isExpanded isCollapsed>
            <Column small={6}>
                <Label>Featuring</Label>
            </Column>
            <Column small={6}>
                <Text>Sam Davey</Text>
                <Text>Sharnie Edwards</Text>
            </Column>
        </Row>
    </Jacket>
);

export default Who;