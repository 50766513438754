// Imports
// ------
import styled, { css, keyframes } from 'styled-components';
import { breakup, H1, P } from '@tackl';

// Exports
// ------
export const Jacket = styled.section((props => `
    position: relative;
    overflow-x: hidden;

    min-height: 100vh;
    padding-top: 3.6rem;

    &:before {
        content: '';
        position: absolute;
        z-index: 1;
        top: -25vw;
        left: 30vw;

        border-radius: 100%;
        width: 60vw;
        height: 60vw;

        background-color: ${props.theme.bc2};
        filter: blur(12vw);
    }
`));

export const Image = styled.div((props => `
    position: absolute;
    top: 0; left: 0;
    z-index: -1;
    height: 100%;
    width: 100vw;

    &:before {
        content: '';
        position: absolute;
        z-index: 1;
        top: 0; left: 0; right: 0; bottom: 0;
        height: 100%;
        background: ${props.theme.bc1};
        opacity: 0.35;
    }
    
    &:after {
        content: '';
        position: absolute;
        z-index: 3;
        left: 0; right: 0; bottom: 0;
        height: 50vh;
        background: linear-gradient(180deg, rgba(18, 20, 33, 0) 0%, #121421 90%);
    }

    > div {
        height: 100%;
    }
`));

export const Content = styled.div((props => css`
    position: relative;
    z-index: 2;
    margin-top: 5vh;

    ${breakup.medium` margin-top: 15vh; `}
    ${breakup.xlarge` margin-top: 25vh; `}
`));

export const Label = styled(P)((props => `
    color: ${props.theme.white};
    font-size: 2.4rem;
    text-transform: uppercase;
    line-height: 1.1;

    ${props.ar && `
        text-align: right;
        margin-top: 10rem;
    `}
`));

export const Title = styled.div((props => `
    position: relative;

    display: block;
    margin-top: 10rem; 
`));

export const Frame = styled.div((props => css`
    > img {
        position: absolute;
        top: 0; right: -2.4rem; bottom: 0;
        height: 100%;

        ${breakup.large`
            width: 100%;
            right: auto;
            left: -2.4rem;
        `}
    }
`));

const Rotate = keyframes`
    to {
        transform: rotate(-360deg);
    }
`;

export const Spinner = styled.div((props => css`
    position: absolute;
    top: -5rem; right: 12vw;
    z-index: 2;
    
    animation: ${Rotate} 12s linear infinite;

    ${breakup.large` top: -3rem; right: 24vw; `}
`));

export const Headline = styled(H1)((props => css`
    display: block;
    padding: 4.8rem 3.6rem;

    font-size: 8vw;
    font-weight: 600;
    color: ${props.theme.white};
    text-transform: uppercase;

    ${breakup.large`
        padding: 6rem;
        font-size: 8.6vw;
    `}
`));