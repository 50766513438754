import { useStaticQuery, graphql } from 'gatsby';

export const useDato = () => {
    const data = useStaticQuery(
        graphql`
            query HomeServices {
                services: datoCmsHomepage {
                    title: servicesTitle
                    serviceList {
                        title
                        description
                        image {
                            alt
                            data: gatsbyImageData (
                                width: 720
                                height: 500
                                placeholder: BLURRED
                                layout: FULL_WIDTH
                            )
                        }
                    }
                }
            }
        `
    );

    return data.services;
}