import { useStaticQuery, graphql } from 'gatsby';

export const useDato = () => {
    const data = useStaticQuery(
        graphql`
            query HomeHero {
                home: datoCmsHomepage {
                    image: heroImage {
                        alt
						data: gatsbyImageData(
							width: 720
							height: 705
							placeholder: BLURRED
							layout: FULL_WIDTH
						)
                    }
                }
            }
        `
    );

    return data.home;
}