// Imports
// ------
import { css } from 'styled-components';
import { breakup } from '@tackl';
import { Column } from './index';

// Exports
// ------
const collapsed = (props) => css`
	/* Is Collapsed */
	${props.isCollapsed &&
	css`
		> ${Column} {
			padding: 0;
		}
	`}

	${props.isCollapsedSmedium &&
	css`
		> ${Column} {
			${breakup.smedium` padding: 0; `}
		}
	`}

    ${props.isCollapsedMedium &&
	css`
		> ${Column} {
			${breakup.medium` padding: 0; `}
		}
	`}

    ${props.isCollapsedLarge &&
	css`
		> ${Column} {
			${breakup.large` padding: 0; `}
		}
	`}

    ${props.isCollapsedXlarge &&
	css`
		> ${Column} {
			${breakup.xlarge` padding: 0; `}
		}
	`}

    ${props.isCollapsedXxlarge &&
	css`
		> ${Column} {
			${breakup.xxlarge` padding: 0; `}
		}
	`}

    ${props.isCollapsedHuge &&
	css`
		> ${Column} {
			${breakup.huge` padding: 0; `}
		}
	`}

    ${props.isCollapsedUber &&
	css`
		> ${Column} {
			${breakup.uber` padding: 0; `}
		}
	`}


    /* Not Collapsed */
    ${props.isNotCollapsedSmedium &&
	css`
		> ${Column} {
			${breakup.smedium`
                padding-left: calc(${props.theme.gutterLarge} / 2);
                padding-right: calc(${props.theme.gutterLarge} / 2);
            `}
		}
	`}

    ${props.isNotCollapsedMedium &&
	css`
		> ${Column} {
			${breakup.medium`
                padding-left: calc(${props.theme.gutterLarge} / 2);
                padding-right: calc(${props.theme.gutterLarge} / 2);
            `}
		}
	`}

    ${props.isNotCollapsedLarge &&
	css`
		> ${Column} {
			${breakup.large`
                padding-left: calc(${props.theme.gutterLarge} / 2);
                padding-right: calc(${props.theme.gutterLarge} / 2);
            `}
		}
	`}

    ${props.isNotCollapsedXlarge &&
	css`
		> ${Column} {
			${breakup.xlarge`
                padding-left: calc(${props.theme.gutterLarge} / 2);
                padding-right: calc(${props.theme.gutterLarge} / 2);
            `}
		}
	`}

    ${props.isNotCollapsedXxlarge &&
	css`
		> ${Column} {
			${breakup.xxlarge`
                padding-left: calc(${props.theme.gutterLarge} / 2);
                padding-right: calc(${props.theme.gutterLarge} / 2);
            `}
		}
	`}

    ${props.isNotCollapsedHuge &&
	css`
		> ${Column} {
			${breakup.huge`
                padding-left: calc(${props.theme.gutterLarge} / 2);
                padding-right: calc(${props.theme.gutterLarge} / 2);
            `}
		}
	`}

    ${props.isNotCollapsedUber &&
	css`
		> ${Column} {
			${breakup.uber`
                padding-left: calc(${props.theme.gutterLarge} / 2);
                padding-right: calc(${props.theme.gutterLarge} / 2);
            `}
		}
	`}
`;

export default collapsed;
