// Imports
// ------
import React from 'react';
import Marquee from 'react-double-marquee';
import Bg from '@images/gallery-bg.svg';
import { GatsbyImage } from 'gatsby-plugin-image';
import { useDato } from './data';

// Styles
// ------
import {
    Jacket,
    Content,
    Blade
} from './styles';

// Component
// ------
const Gallery = () => {
    // NOTE • Data
    const {
        images
    } = useDato();

    return (
        <Jacket className="cursor-intro" padBottom>
            <Marquee direction="left" speed={0.05}>
                <Content>
                    {
                        images.map((image) => (
                            <>
                                <GatsbyImage image={image.data} alt={image.alt} />
                            </>
                        ))
                    }
                </Content>
            </Marquee>
            <Blade>
                <img src={Bg} alt="" />
            </Blade>
        </Jacket>
    )
}

export default Gallery;