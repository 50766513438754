// Imports
// ------
import styled from 'styled-components';
import { breakup, Section, H5, P } from '@tackl';

// Exports
// ------
export const Jacket = styled(Section)((props => `
    position: relative;
    min-height: 60vh;
`));

export const Line = styled.div((props => `
    position: relative;
    display: block;
    margin-bottom: 6rem;

    width: 10rem;
    height: 1px;
    background: ${props.theme.bc2};
`));

export const Title = styled(H5)((props => `
    text-transform: initial;
    margin-bottom: 2.4rem;

    ${breakup.xlarge && ` margin-bottom: 3.6rem; `}
`));

export const Para = styled(P)((props => `
    color: ${props.theme.white};
`));