// Imports
// ------
import React from 'react';
import Img from '@images/logo.svg';

// Styles
// ------
import { Jacket } from './styles';

// Component
// ------
const Logo = () => (
    <Jacket>
        <img src={Img} alt="" />
    </Jacket>
);

export default Logo;